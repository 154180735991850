var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "800", persistent: "", scrollable: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "btn ml-2",
                      attrs: {
                        text: "",
                        disabled:
                          _vm.btnDisabledFileUpload || _vm.fileuploadbtndisabled
                      }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(_vm._s(_vm.$t("File Upload")))]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.fileUploadVisible,
        callback: function($$v) {
          _vm.fileUploadVisible = $$v
        },
        expression: "fileUploadVisible"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "popup add_store_popup" },
        [
          _c("v-card-title", [
            _c("h3", { staticClass: "page-title-bar" }, [
              _c("i", { staticClass: "ico ico-product" }),
              _vm._v(_vm._s(_vm.$t("File Upload")) + " ")
            ])
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-tabs",
                {
                  attrs: { "fixed-tabs": "" },
                  on: { change: _vm.changeTab },
                  model: {
                    value: _vm.tabData,
                    callback: function($$v) {
                      _vm.tabData = $$v
                    },
                    expression: "tabData"
                  }
                },
                [
                  _c("v-tab", [_vm._v(_vm._s(_vm.$t("UPDATE NOW")))]),
                  _c("v-tab", [_vm._v(_vm._s(_vm.$t("UPDATE LATER")))]),
                  _c("v-tab-item", { staticClass: "tabitemclass" }, [
                    _c(
                      "div",
                      { staticStyle: { padding: "25px" } },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              [
                                _c("v-file-input", {
                                  staticClass: "form-file",
                                  attrs: {
                                    outlined: "",
                                    dense: "",
                                    "hide-details": "",
                                    "prepend-icon": "",
                                    solo: "",
                                    flat: "",
                                    placeholder: "" + _vm.$t("Select file")
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append",
                                      fn: function() {
                                        return [
                                          _c("img", {
                                            attrs: {
                                              width: "24",
                                              height: "24",
                                              src: require("@/assets/img/ico-upload-file.gif")
                                            }
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ]),
                                  model: {
                                    value: _vm.files,
                                    callback: function($$v) {
                                      _vm.files = $$v
                                    },
                                    expression: "files"
                                  }
                                }),
                                _c("p", { staticClass: "txt-s mt-1 ml-2" }, [
                                  _vm._v("* Max. File Size : 14MB")
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "v-tab-item",
                    {
                      staticStyle: {
                        padding: "15px",
                        "background-color": "#EFEFEF",
                        "max-height": "700px !important",
                        "overflow-y": "auto"
                      }
                    },
                    [
                      _c("div", { staticStyle: { padding: "25px" } }, [
                        _c(
                          "div",
                          { staticClass: "product_detail" },
                          [
                            _c("v-select", {
                              staticClass: "form-select mb-4 mandatory",
                              attrs: {
                                label: _vm.$t("Time Zone") + "*",
                                placeholder: _vm.$t("Select Time Zone"),
                                items: _vm.timezoneList,
                                outlined: "",
                                dense: "",
                                "hide-details": "",
                                clearable: "",
                                "item-text": _vm.getFieldText,
                                "item-value": "name"
                              },
                              on: {
                                "click:clear": _vm.clearTimeZone,
                                click: _vm.clearTimeZone
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "prepend-item",
                                  fn: function() {
                                    return [
                                      _c("v-text-field", {
                                        ref: "searchTerm",
                                        staticClass:
                                          "form-input search-v-select",
                                        attrs: {
                                          solo: "",
                                          outlined: "",
                                          "hide-details": "",
                                          dense: "",
                                          placeholder: _vm.$t("Search Timezone")
                                        },
                                        on: { input: _vm.searchTimezone },
                                        model: {
                                          value: _vm.searchTerm,
                                          callback: function($$v) {
                                            _vm.searchTerm = $$v
                                          },
                                          expression: "searchTerm"
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              ]),
                              model: {
                                value: _vm.timezone,
                                callback: function($$v) {
                                  _vm.timezone = $$v
                                },
                                expression: "timezone"
                              }
                            }),
                            _c(
                              "v-menu",
                              {
                                attrs: {
                                  "close-on-content-click": false,
                                  transition: "scale-transition",
                                  "offset-y": "",
                                  "min-width": "250px"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                ref: "calendarstart",
                                                staticClass:
                                                  "form-select mb-4 col mandatory",
                                                attrs: {
                                                  label:
                                                    _vm.$t("Schedule Time") +
                                                    "*",
                                                  placeholder:
                                                    "" +
                                                    _vm.$t(
                                                      "Select Schedule Time"
                                                    ),
                                                  outlined: "",
                                                  dense: "",
                                                  "hide-details": "",
                                                  readonly: "",
                                                  clearable: "",
                                                  "prepend-icon": "",
                                                  "append-icon":
                                                    "mdi-calendar-month"
                                                },
                                                on: {
                                                  "click:clear": function(
                                                    $event
                                                  ) {
                                                    _vm.scheduleTime = null
                                                  },
                                                  click: _vm.setCurrentDate,
                                                  "click:append": function(
                                                    $event
                                                  ) {
                                                    _vm.$refs.calendarstart.$el
                                                      .querySelector("input")
                                                      .click()
                                                  }
                                                },
                                                model: {
                                                  value: _vm.scheduleTime,
                                                  callback: function($$v) {
                                                    _vm.scheduleTime = $$v
                                                  },
                                                  expression: "scheduleTime"
                                                }
                                              },
                                              "v-text-field",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        )
                                      ]
                                    }
                                  }
                                ]),
                                model: {
                                  value: _vm.schedule.showStartDatePicker,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.schedule,
                                      "showStartDatePicker",
                                      $$v
                                    )
                                  },
                                  expression: "schedule.showStartDatePicker"
                                }
                              },
                              [
                                _c("date-picker", {
                                  attrs: {
                                    mode: "dateTime",
                                    "min-date": new Date(),
                                    "minute-increment": 30,
                                    "is-required": true,
                                    is24hr: ""
                                  },
                                  model: {
                                    value: _vm.startTime,
                                    callback: function($$v) {
                                      _vm.startTime = $$v
                                    },
                                    expression: "startTime"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { staticStyle: { "margin-top": "-12px" } },
                                  [
                                    _c("p", { staticClass: "label-txt mb-2" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "Browse File ( Extension defined in"
                                            )
                                          ) +
                                          " "
                                      ),
                                      _c("b", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "System Config > Product File Config"
                                              )
                                            )
                                        )
                                      ]),
                                      _vm._v(" ) "),
                                      _c("span", { staticClass: "redbold" }, [
                                        _vm._v("*")
                                      ])
                                    ]),
                                    _c("v-file-input", {
                                      staticClass: "form-file",
                                      attrs: {
                                        outlined: "",
                                        dense: "",
                                        "hide-details": "",
                                        solo: "",
                                        flat: "",
                                        placeholder: "" + _vm.$t("Select File"),
                                        "prepend-icon": "",
                                        multiple: "false",
                                        "append-icon": "mdi-file-upload",
                                        clearable: false
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.appendFilesLater()
                                        }
                                      },
                                      model: {
                                        value: _vm.filesLater,
                                        callback: function($$v) {
                                          _vm.filesLater = $$v
                                        },
                                        expression: "filesLater"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c("v-col", [
                                  _c("p", { staticClass: "label-txt" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("Selected Files")) +
                                        " "
                                    ),
                                    _c("span", { staticClass: "redbold" }, [
                                      _vm._v(" * ")
                                    ])
                                  ]),
                                  _c(
                                    "ul",
                                    { staticClass: "selectedFile-list mt-2" },
                                    [
                                      _vm.filesToUploadLater.length > 0
                                        ? _vm._l(
                                            _vm.filesToUploadLater,
                                            function(file, i) {
                                              return _c(
                                                "li",
                                                { key: file.name },
                                                [
                                                  i ===
                                                  _vm.filesToUploadLater
                                                    .length -
                                                    1
                                                    ? _c("p", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(file.name) +
                                                            " "
                                                        )
                                                      ])
                                                    : _vm._e(),
                                                  i ===
                                                  _vm.filesToUploadLater
                                                    .length -
                                                    1
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            text: "",
                                                            icon: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.removeFileLater(
                                                                file.name
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              width: "20",
                                                              height: "20",
                                                              src: require("@/assets/img/ico-delete2.png")
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                      _vm.filesToUploadLater.length === 0
                                        ? [
                                            _c("li", [
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("No files selected")
                                                  )
                                                )
                                              ])
                                            ])
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.tabData === 0
            ? _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.uploadFiles()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Upload")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.cancelFileUpload()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Cancel")))]
                  )
                ],
                1
              )
            : _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.handleSaveClick()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Upload")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.cancelFileUpload()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Cancel")))]
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }