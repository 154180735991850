import { render, staticRenderFns } from "./FileUpload.vue?vue&type=template&id=10e35f39&scoped=true&"
import script from "./FileUpload.vue?vue&type=script&lang=js&"
export * from "./FileUpload.vue?vue&type=script&lang=js&"
import style0 from "./FileUpload.vue?vue&type=style&index=0&id=10e35f39&scoped=true&lang=css&"
import style1 from "./FileUpload.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10e35f39",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VCol,VDialog,VFileInput,VMenu,VRow,VSelect,VTab,VTabItem,VTabs,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/-36BVGaF/0/applicationg/aims-dashboard/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('10e35f39')) {
      api.createRecord('10e35f39', component.options)
    } else {
      api.reload('10e35f39', component.options)
    }
    module.hot.accept("./FileUpload.vue?vue&type=template&id=10e35f39&scoped=true&", function () {
      api.rerender('10e35f39', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Product/modal/FileUpload.vue"
export default component.exports