var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          key: _vm.productDetailVisible,
          attrs: { scrollable: "", persistent: "", width: "700" },
          model: {
            value: _vm.productDetailVisible,
            callback: function($$v) {
              _vm.productDetailVisible = $$v
            },
            expression: "productDetailVisible"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "popup" },
            [
              _c("v-card-title", [
                _c("div", { staticClass: "popup-titbox _between" }, [
                  _c("h3", { staticClass: "page-title-bar" }, [
                    _c("i", { staticClass: "ico ico-product" }),
                    _vm._v(_vm._s(_vm.$t("Product Detail")) + " ")
                  ])
                ])
              ]),
              _c("v-card-text", [
                _c("div", {}, [
                  _c("div", { staticClass: "productInfo d-flex" }, [
                    _c(
                      "div",
                      { staticClass: "list" },
                      [
                        _c("v-data-table", {
                          staticClass: "tbl-type01",
                          attrs: {
                            headers: _vm.pdHeaders,
                            items: _vm.pdItems,
                            page: _vm.page,
                            "hide-default-footer": true,
                            "item-key": "no"
                          },
                          on: {
                            "update:page": function($event) {
                              _vm.page = $event
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c("div", { staticClass: "product_detail" }, [
                      _c(
                        "table",
                        [
                          _c("colgroup", [
                            _c("col", { staticStyle: { width: "100px" } }),
                            _c("col")
                          ]),
                          _vm._l(_vm.articleDataList, function(
                            articleData,
                            idx
                          ) {
                            return _c("tr", { key: idx }, [
                              _c("th", { attrs: { disabled: idx < 1 } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.editArticleHeader(
                                        articleData.key,
                                        idx
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _c(
                                "td",
                                [
                                  _c("v-text-field", {
                                    staticClass: "form-input flat rounded-0",
                                    attrs: {
                                      placeholder: _vm.getPlaceholder(
                                        articleData.key
                                      ),
                                      disabled: idx < 1,
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "",
                                      solo: "",
                                      clearable: ""
                                    },
                                    on: {
                                      "click:clear": _vm.onClear,
                                      keydown: _vm.clearTabindex,
                                      input: function($event) {
                                        return _vm.setText(
                                          articleData.key,
                                          articleData.value
                                        )
                                      }
                                    },
                                    model: {
                                      value: articleData.value,
                                      callback: function($$v) {
                                        _vm.$set(articleData, "value", $$v)
                                      },
                                      expression: "articleData.value"
                                    }
                                  }),
                                  articleData.key === "NFC URL"
                                    ? _c("span", [
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "font-size": "12px",
                                              color: "red",
                                              "font-weight": "700"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "NFC URL max length : 240 - (Currently :" +
                                                _vm._s(
                                                  _vm.datalength === null
                                                    ? 0
                                                    : _vm.datalength
                                                ) +
                                                ")"
                                            )
                                          ]
                                        )
                                      ])
                                    : _vm._e()
                                ],
                                1
                              )
                            ])
                          }),
                          _c("tr", [
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("Assigned Label")))
                            ]),
                            _c("td", [
                              _c(
                                "div",
                                { staticClass: "label-list" },
                                [
                                  _vm._l(_vm.assignedLabels, function(
                                    assignedLabel
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: assignedLabel,
                                        staticClass: "d-flex align-baseline"
                                      },
                                      [
                                        _c("v-text-field", {
                                          staticClass:
                                            "form-input flat rounded-0",
                                          attrs: {
                                            value: assignedLabel,
                                            placeholder: _vm.$t(
                                              "Input the label ID"
                                            ),
                                            clearable: "",
                                            dense: "",
                                            "hide-details": "",
                                            solo: ""
                                          }
                                        }),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "btn flat ml-2",
                                            attrs: {
                                              disabled:
                                                _vm.productAssignDisabled ||
                                                _vm.labelUnassignDisabled,
                                              text: "",
                                              width: "80"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.unassignArticle(
                                                  assignedLabel
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("Unassign")) + " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  _vm._l(_vm.labelsToAssign, function(
                                    labelToAssign,
                                    idx
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: idx,
                                        staticClass: "d-flex align-baseline"
                                      },
                                      [
                                        _c("v-text-field", {
                                          staticClass:
                                            "form-input flat rounded-0",
                                          attrs: {
                                            value: _vm.labelsToAssign[idx],
                                            placeholder: _vm.$t(
                                              "Input the label ID"
                                            ),
                                            dense: "",
                                            "hide-details": "",
                                            solo: "",
                                            clearable: ""
                                          },
                                          on: {
                                            keyup: function($event) {
                                              $event.preventDefault()
                                              return _vm.handleLabelIdKeyUp(
                                                idx,
                                                $event
                                              )
                                            }
                                          }
                                        }),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "btn basic flat ml-2",
                                            attrs: {
                                              disabled:
                                                _vm.productAssignDisabled ||
                                                _vm.labelUnassignDisabled,
                                              width: "80",
                                              text: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.assignArticle(
                                                  _vm.labelsToAssign[idx]
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(_vm._s(_vm.$t("Assign")))]
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex justify-center" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { text: "", icon: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.addNewLabelInput()
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/img/ico-add.jpg"),
                                              alt: ""
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                2
                              )
                            ])
                          ])
                        ],
                        2
                      )
                    ])
                  ])
                ])
              ]),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: {
                        disabled: _vm.productDetailUpdateDisabled,
                        text: "",
                        icon: ""
                      },
                      on: { click: _vm.updateArticle }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Update")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: { click: _vm.closeProductDetail }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "400" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "popup add_store_popup",
              attrs: { align: "center", justify: "center" }
            },
            [
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.errormessage) }
                    })
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: { click: _vm.addWhitelist }
                    },
                    [_vm._v("Yes")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v("No")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "400" },
          model: {
            value: _vm.assigndialog,
            callback: function($$v) {
              _vm.assigndialog = $$v
            },
            expression: "assigndialog"
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "popup add_store_popup",
              attrs: { align: "center", justify: "center" }
            },
            [
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.messageText) }
                    })
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.assignArticle(_vm.labelsToAssign[0])
                        }
                      }
                    },
                    [_vm._v("Yes")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.assigndialog = false
                        }
                      }
                    },
                    [_vm._v("No")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }