<template>
  <v-dialog v-model="fileUploadVisible" width="800" persistent scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text
        :disabled="btnDisabledFileUpload || fileuploadbtndisabled"
        v-bind="attrs"
        v-on="on"
        class="btn ml-2"
        >{{ $t("File Upload") }}</v-btn
      >
    </template>
    <v-card class="popup add_store_popup">
      <v-card-title>
        <h3 class="page-title-bar">
          <i class="ico ico-product"></i>{{ $t("File Upload") }}
        </h3>
      </v-card-title>
      <!-- new changes -->
      <v-card-text>
        <v-tabs fixed-tabs v-model="tabData" @change="changeTab">
          <v-tab>{{ $t("UPDATE NOW") }}</v-tab>
          <v-tab>{{ $t("UPDATE LATER") }}</v-tab>
          <v-tab-item class="tabitemclass">
            <div style="padding: 25px">
              <v-row>
                <v-col>
                  <v-file-input
                    outlined
                    dense
                    hide-details
                    prepend-icon
                    solo
                    flat
                    :placeholder="`${$t('Select file')}`"
                    class="form-file"
                    v-model="files"
                  >
                    <template v-slot:append>
                      <img
                        width="24"
                        height="24"
                        src="@/assets/img/ico-upload-file.gif"
                      />
                    </template>
                  </v-file-input>
                  <p class="txt-s mt-1 ml-2">* Max. File Size : 14MB</p>
                </v-col>
              </v-row>
            </div>
          </v-tab-item>
          <!-- Upload Later -->
          <v-tab-item
            style="
            padding: 15px;
            background-color: #EFEFEF;
            max-height: 700px !important;
            overflow-y: auto;
          "
          >
            <div style="padding: 25px">
              <div class="product_detail">
                <v-select
                  v-model="timezone"
                  :label="`${$t('Time Zone')}*`"
                  :placeholder="$t('Select Time Zone')"
                  :items="timezoneList"
                  class="form-select mb-4 mandatory"
                  outlined
                  dense
                  hide-details
                  clearable
                  :item-text="getFieldText"
                  item-value="name"
                  @click:clear="clearTimeZone"
                  @click="clearTimeZone"
                >
                  <template v-slot:prepend-item>
                    <v-text-field
                      solo
                      outlined
                      hide-details
                      dense
                      :placeholder="$t('Search Timezone')"
                      ref="searchTerm"
                      v-model="searchTerm"
                      @input="searchTimezone"
                      class="form-input search-v-select"
                    ></v-text-field>
                  </template>
                </v-select>
                <v-menu
                  v-model="schedule.showStartDatePicker"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="250px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="scheduleTime"
                      @click:clear="scheduleTime = null"
                      :label="`${$t('Schedule Time')}*`"
                      :placeholder="`${$t('Select Schedule Time')}`"
                      class="form-select mb-4 col mandatory"
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                      hide-details
                      readonly
                      clearable
                      prepend-icon=""
                      append-icon="mdi-calendar-month"
                      ref="calendarstart"
                      @click="setCurrentDate"
                      @click:append="
                        $refs.calendarstart.$el.querySelector('input').click()
                      "
                    >
                    </v-text-field>
                  </template>
                  <date-picker
                    mode="dateTime"
                    v-model="startTime"
                    :min-date="new Date()"
                    :minute-increment="30"
                    :is-required="true"
                    is24hr
                  />
                </v-menu>
                <v-row>
                  <v-col style="margin-top: -12px">
                    <p class="label-txt mb-2">
                      {{ $t('Browse File ( Extension defined in') }}
                      <b>  {{ $t(`System Config > Product File Config`) }}</b> )
                      <span class="redbold">*</span>
                    </p>
                    <v-file-input
                      outlined
                      dense
                      hide-details
                      solo
                      flat
                      :placeholder="`${$t('Select File')}`"
                      class="form-file"
                      v-model="filesLater"
                      @change="appendFilesLater()"
                      prepend-icon=""
                      multiple="false"
                      append-icon="mdi-file-upload"
                      :clearable="false"
                    >
                    </v-file-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <p class="label-txt">
                      {{ $t("Selected Files") }}
                      <span class="redbold"> * </span>
                    </p>
                    <ul class="selectedFile-list mt-2">
                      <template v-if="filesToUploadLater.length > 0">
                        <li
                          v-for="(file, i) in filesToUploadLater"
                          :key="file.name"
                        >
                          <p v-if="i === filesToUploadLater.length - 1">
                            {{ file.name }}
                          </p>
                          <v-btn
                            @click="removeFileLater(file.name)"
                            text
                            icon
                            v-if="i === filesToUploadLater.length - 1"
                            ><img
                              width="20"
                              height="20"
                              src="@/assets/img/ico-delete2.png"
                          /></v-btn>
                        </li>
                      </template>
                      <template v-if="filesToUploadLater.length === 0">
                        <li>
                          <p>{{ $t("No files selected") }}</p>
                        </li>
                      </template>
                    </ul>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions v-if="tabData === 0" class="d-flex justify-center">
        <v-btn text icon @click="uploadFiles()" class="btn">{{
          $t("Upload")
        }}</v-btn>
        <v-btn text icon @click="cancelFileUpload()" class="btn">{{
          $t("Cancel")
        }}</v-btn>
      </v-card-actions>
      <v-card-actions v-else class="d-flex justify-center">
        <v-btn text icon @click="handleSaveClick()" class="btn">{{
          $t("Upload")
        }}</v-btn>
        <v-btn text icon @click="cancelFileUpload()" class="btn">{{
          $t("Cancel")
        }}</v-btn>
      </v-card-actions>
      <!-- end -->
    </v-card>
  </v-dialog>
</template>
<script>
import EventBus from '@/plugins/eventBus'
import commons from '@/plugins/commons'
// Mixins
import MsgBoxParent from '@/mixins/MsgBoxParent'
import FileUploader from '@/mixins/FileUploader'
import { DatePicker } from 'v-calendar'
import moment from 'moment'
import codes from '@/plugins/codes'
// import Template from '../../../lcd/views/Template/Template.vue'
export default {
  // components: { Template },
  components: {
    DatePicker
  },
  mixins: [FileUploader, MsgBoxParent],

  props: {
    btnDisabledFileUpload: Boolean,
    store: Object,
    fileuploadbtndisabled: Boolean
  },
  data () {
    const startTime = new Date()
    startTime.setMinutes(0, 0, 0)

    return {
      // new changes
      tabData: 0,
      timezone: '',
      timezoneList: [],
      searchTerm: '',
      schedule: {},
      scheduleTime: '',
      fileUploadFormat: '',
      filesToUploadLater: [],
      startTime,
      //
      productionType: process.env.VUE_APP_PRODUCTION_TYPE,
      requests: {
        uploadFiles: {
          method: 'post',
          url: '/api/common/articles/upload'
        },
        uploadFileslater: {
          method: 'post',
          url: '/api/common/articles/schedule'
        }
      },
      files: null,
      filesToUpload: [],
      fileUploadVisible: false,
      filesLater: []
    }
  },
  watch: {
    startTime (value) {
      this.scheduleTime = moment(value).format('YYYY-MM-DD HH:mm')
    },
    filesLater (e) {
      console.log(e)
    },
    scheduledTime (val) {
      console.log(val)
    }
  },
  mounted () {
    this.getTimezones()
  },
  methods: {
    // new changes
    changeTab () {
      this.files = null
      this.filesToUpload = ''
      this.scheduleTime = ''
      this.timezone = ''
      this.filesLater = []
      this.filesToUploadLater = []
      console.log('tabssssssss')
    },
    AddRequestType () {
      console.log('addrequest')
    },
    setCurrentDate (e) {
      if (!this.scheduleTime) {
        console.log(e)
        setTimeout(() => {
          this.scheduleTime = moment(new Date()).format('YYYY-MM-DD HH:00')
        }, 200)
      }
    },
    clearTimeZone () {
      this.$refs.searchTerm.focus()
      this.searchTerm = ''
      this.searchTimezone(this.timezoneList)
    },
    searchTimezone (e) {
      if (!this.searchTerm) {
        this.timezoneList = this.timezoneCopy
      } else {
        this.timezoneList = this.timezoneCopy.filter((fruit) => {
          return (
            fruit.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
          )
        })
      }
    },
    getTimezones () {
      const config = { params: {} }
      return this.$utils
        .callAxios(
          codes.requests.getTimezones.method,
          codes.requests.getTimezones.url,
          config
        )
        .then((res) => {
          this.timezoneList = res.data.timezoneList
          this.timezoneCopy = this.timezoneList
        })
    },
    getFieldText (item) {
      return `(${item.timezone}) ${item.name}`
    },
    //
    cancelFileUpload () {
      this.files = null
      this.filesToUpload = []
      this.toggleFileUpload()
      this.scheduleTime = ''
      this.timezone = ''
      this.tabData = 0
    },
    toggleFileUpload () {
      this.fileUploadVisible = !this.fileUploadVisible
    },
    appendFiles () {
      this.filesToUpload = [...this.filesToUpload, ...this.files]
      this.files = []
    },
    removeFile (fileName) {
      this.filesToUpload = this.filesToUpload.filter(
        (file) => file.name !== fileName
      )
    },
    appendFilesLater (e) {
      this.filesToUploadLater = []
      this.filesToUploadLater = this.filesLater
    },
    removeFileLater () {
      this.filesToUploadLater = []
      this.filesLater = []
    },
    async buildFileUploadBody (files) {
      console.log(files)
      const fileDataList = []
      const fileData = {}
      fileData.contentType = 'IMAGE'
      fileData.imgBase64 = await commons.getBase64(files)
      fileData.pageIndex = 1
      fileData.fileName = files.name
      fileData.size = files.size
      fileDataList.push(fileData)
      return fileDataList
    },
    async uploadFiles () {
      console.log(this.files)
      // const files = this.filesToUpload
      if (this.files === null || this.files === undefined) {
        EventBus.$emit('messageAlert', this.$t('Select files to upload.'))
        return
      }
      const reqBody = await this.buildFileUploadBody(this.files)
      var sizeInMB = (reqBody[0].size / (1024 * 1024)).toFixed(2)
      if (sizeInMB > 14) {
        EventBus.$emit(
          'messageAlert',
          this.$t('Files larger than 14MB cannot be uploaded.')
        )
        return
      }
      const params = { store: this.store.code }
      const config = { params: params }
      const body = {
        contentType: reqBody[0].contentType,
        imgBase64: reqBody[0].imgBase64,
        pageIndex: reqBody[0].pageIndex,
        fileName: reqBody[0].fileName
      }

      if (this.productionType === 'cloud') {
        params.company = this.$store.state.auth.user.company
      }

      this.$utils
        .callAxiosWithBody(
          this.requests.uploadFiles.method,
          this.requests.uploadFiles.url,
          body,
          config
        )
        .then((res) => {
          EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
          this.cancelFileUpload()
        })
        .catch((error) => {
          switch (error.response.status) {
            default:
              EventBus.$emit(
                'messageAlert',
                this.$t(
                  'The file format is not matched with the field configuration. Please check the configuration in Product File Config.'
                )
              )
              break
          }
          this.cancelFileUpload()
        })
    },

    // Upload later
    async buildFileUploadBodyUploadLater (files) {
      console.log(files)
      const fileDataList = []
      const fileData = {}
      fileData.imgBase64 = await commons.getBase64(files)
      fileData.pageIndex = 0
      fileData.fileName = files.name
      fileData.size = files.size
      fileDataList.push(fileData)
      return fileDataList
    },

    async handleSaveClick () {
      console.log(this.timezone)
      console.log(this.filesLater)
      if (
        this.timezone === null ||
        this.timezone === undefined ||
        this.timezone === ''
      ) {
        EventBus.$emit('messageAlert', this.$t('Select Time Zone.'))
        return
      }
      if (
        this.scheduleTime === null ||
        this.scheduleTime === undefined ||
        this.scheduleTime === ''
      ) {
        EventBus.$emit('messageAlert', this.$t('Select Schedule Time.'))
        return
      }
      if (
        this.filesLater === null ||
        this.filesLater === undefined ||
        this.filesLater === '' ||
        this.filesLater.length === 0
      ) {
        EventBus.$emit('messageAlert', this.$t('Select files to upload.'))
        return
      }
      const reqBody = await this.buildFileUploadBodyUploadLater(
        this.filesLater[0]
      )
      console.log(reqBody)
      var sizeInMB = (reqBody[0].size / (1024 * 1024)).toFixed(2)
      if (sizeInMB > 14) {
        EventBus.$emit(
          'messageAlert',
          this.$t('Files larger than 14MB cannot be uploaded.')
        )
        return
      }
      const params = { store: this.store.code }
      const config = { params: params }
      const body = {
        contentType: reqBody[0].contentType,
        binaryData: reqBody[0].imgBase64,
        id: reqBody[0].pageIndex,
        fileName: reqBody[0].fileName,
        scheduleTime: this.scheduleTime,
        timezone: this.timezone
      }

      if (this.productionType === 'cloud') {
        params.company = this.$store.state.auth.user.company
      }

      this.$utils
        .callAxiosWithBody(
          this.requests.uploadFileslater.method,
          this.requests.uploadFileslater.url,
          body,
          config
        )
        .then((res) => {
          EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
          this.cancelFileUpload()
        })
        .catch((error) => {
          switch (error.response.status) {
            default:
              EventBus.$emit(
                'messageAlert',
                this.$t(
                  'The file format is not matched with the field configuration. Please check the configuration in Product File Config.'
                )
              )
              break
          }
          this.cancelFileUpload()
        })
    }
  }
}
</script>

<style scoped>
.radioBtnWrapper .radioItem {
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  width: auto;
  min-width: 114px;
  margin: 0 10px;
  text-align: center;
  height: 100px;
  display: flex;
  flex-direction: column;
}
::v-deep .v-tabs > .v-tabs-bar .v-tab.v-tab--active {
  border-bottom: 3px solid #999 !important;
}
::v-deep .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  background: #fff !important;
}
::v-deep .v-text-field--outlined > .v-input__control > .v-input__slot {
  background: #fff;
}
.selectedFile-list {
  background-color: #fff;
}
::v-deep .search-v-select > .v-input__control > .v-input__slot fieldset {
  border: 1px solid transparent !important;
}
.placeholder {
  position: absolute;
  z-index: 3;
  color: #aaa;
  top: 0px;
  display: block;
  left: 0px;
  padding: 17px 15px 9px;
  width: 100%;
}
</style>
<style >
.search-v-select {
  height: 37px;
  padding: 0 5px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 2;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  overflow: hidden;
}
.redbold {
  color: red;
  font-weight: bold;
}
.mandatory .v-label.v-label--active:after,
.mandatory .v-label:after {
  content: "*";
  color: red;
  margin-left: -5px;
  font-weight: bold;
}
.tabitemclass {
  padding: 15px;
  background-color: #efefef;
  max-height: 700px !important;
  overflow-y: auto;
}
</style>
